/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to create footer component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import React, { memo } from 'react';
import { Typography, Box } from '@mui/material';

/* Local Imports */
import styles from '../index.style';

// ----------------------------------------------------------------------
/**
 * Footer Component for the authentication pages.
 *
 * @component
 * @returns {JSX.Element}
 */
function Footer() {
  /* Output */
  return (
    <Box sx={styles.footer}>
      <Typography variant="caption">
        © {new Date().getFullYear()} Techechelons Infosolutions Pvt. Ltd. All
        rights reserved.
      </Typography>
    </Box>
  );
}

export default memo(Footer);
