/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Link component to override default Mui Link's style.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 14/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/**
 * Link contains the styles to override default Mui Link and it's children's styles.
 *
 * @component
 * @returns Add-on styles for MuiLink
 */
export default function Link(theme) {
  /* Output */
  return {
    MuiLink: {
      defaultProps: {
        variant: 'h6'
      },
      styleOverrides: {
        root: {
          fontFamily: theme.typography.fonts.Mulish_SemiBold
        }
      }
    }
  };
}
