/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description ControlLabel component to override default Mui ControlLabel's style.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 14/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/**
 * ControlLabel contains the styles to override default Mui ControlLabel and it's children's styles.
 *
 * @component
 * @param theme - global theme object to provide colors, fonts, spacing, shadows etc.
 * @returns Add-on styles for MuiControlLabel
 */
export default function ControlLabel(theme) {
  /* Output */
  return {
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          ...theme.typography.body1
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(1),
          marginLeft: theme.spacing(0)
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          ...theme.typography.body1,
          color: theme.palette.text.primary
        },
        asterisk: {
          color: theme.palette.error.main
        }
      }
    },
    // MuiFilledInput: {
    //   styleOverrides: {
    //     input: {
    //       height: 56,
    //       paddingBottom: 0
    //     }
    //   }
    // },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          left: '-10px'
        }
      }
    }
  };
}
