/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Service Modal component for accessing the services page of website
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 23/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */
// ----------------------------------------------------------------------

/* Imports */
import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Grid } from '@mui/material';

/* Relative Imports */
import { services } from 'constants/appConstant';

/* Local Imports */
import ServicesModalItem from './ServicesModalItem';
import styles from './index.style';

/**
 * Component for service modal of services.
 *
 * @component
 *
 * @returns {JSX.Element}
 */
function ServicesModal({ data }) {
  /* Hooks */
  const { pathname } = useLocation();
  /* Output */
  return (
    <Box sx={styles.rootStyle}>
      <Grid container spacing={6}>
        {data.map((item, index) => (
          <Grid key={index} item xs={12} sm={12} md={4}>
            <ServicesModalItem
              isSelected={pathname.includes(item.link)}
              serviceIcon={item.icon}
              serviceName={item.name}
              serviceLink={item.link}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default memo(ServicesModal);
