/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to create styles for services modal.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 23/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */
// ----------------------------------------------------------------------

/**
 * Style object for services modal
 *
 * @return {object}
 */
export default {
  rootStyle: {
    p: 6
  },
  serviceIcon: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: theme.palette.background.icon,
    mr: 1.75,
    padding: theme.spacing(1.25),
    width: 48,
    height: 48,
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%'
    },
    [theme.breakpoints.down('md')]: {
      width: 32,
      height: 32,
      padding: theme.spacing(1)
    }
  }),
  serviceTitle: (theme) => ({
    color: theme.palette.common.white,
    lineHeight: 1.2,
    fontFamily: theme.typography.fontFamily,
    [theme.breakpoints.down('md')]: {
      ...theme.typography.body2
    }
  }),
  servicesModalItemLink: (theme) => ({
    '&:hover': {
      '& svg': {
        fill: theme.palette.secondary.main
      },
      '& .MuiTypography-root': {
        color: theme.palette.secondary.main
      }
    }
  }),
  servicesModalItemSelected: (theme) => ({
    '& svg': {
      fill: theme.palette.secondary.main
    },
    '& .MuiTypography-root': {
      color: theme.palette.secondary.main
    }
  }),
  servicesModalItemBox: {
    display: 'inline-flex',
    alignItems: 'center'
  }
};
