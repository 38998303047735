/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Service Modal Item Builder component for services modal of website
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 23/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */
// ----------------------------------------------------------------------

/* Imports */
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box, Link, Typography } from '@mui/material';

/* Local Imports */
import styles from './index.style';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 *
 * @property {node} serviceIcon
 * @property {string} serviceName
 * @property {string} serviceLink
 * @property {boolean} isSelected
 *
 */
const propTypes = {
  serviceIcon: PropTypes.node.isRequired,
  serviceName: PropTypes.string.isRequired,
  serviceLink: PropTypes.string,
  isSelected: PropTypes.bool
};

/**
 * @typedef defaultProps
 *
 * @property {string} serviceLink
 * @property {boolean} isSelected
 *
 */
const defaultProps = {
  serviceLink: '',
  isSelected: false
};

// ----------------------------------------------------------------------

/**
 * Component for Service Modal Item Builder in services modal.
 *
 * @component
 *
 * @param {node} serviceIcon - icon of service.
 * @param {string} serviceName - name of service.
 * @param {string} serviceLink - link to related service page.
 * @returns {JSX.Element}
 */
function ServicesModalItem({
  serviceIcon,
  serviceName,
  serviceLink,
  isSelected
}) {
  /* Output */
  return (
    <Link
      underline="none"
      href={serviceLink}
      sx={[
        styles.servicesModalItemLink,
        isSelected ? styles.servicesModalItemSelected : {}
      ]}
    >
      <Box sx={styles.servicesModalItemBox}>
        <Box sx={styles.serviceIcon}>{serviceIcon}</Box>
        <Box flex={1}>
          <Typography variant="body1" sx={styles.serviceTitle}>
            {serviceName}
          </Typography>
        </Box>
      </Box>
    </Link>
  );
}

ServicesModalItem.propTypes = propTypes;
ServicesModalItem.defaultProps = defaultProps;
export default memo(ServicesModalItem);
