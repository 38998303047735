/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to define the constants.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 16/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------
/* Imports */
import {
  Email as EmailIcon,
  LocationOn as LocationOnIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon
} from '@mui/icons-material';

/* Relative Imports */
import { PAGES } from 'routes/paths';
import {
  AiMlIcon,
  AppDevelopmentIcon,
  BlockChainIcon,
  ConfidenceIcon,
  CustomerSupportIcon,
  DevOpsIcon,
  EcommerceIcon,
  EduTechIcon,
  EndToEndIcon,
  EngagementModalIcon,
  FitnessAndSportIcon,
  HackathonIcon,
  HealthCareIcon,
  LeadershipIcon,
  LiveProjectsIcon,
  MarketingAndAdvertisingIcon,
  MentorIcon,
  OfficeIcon,
  ProjectManagementIcon,
  RetailIcon,
  SkillSetIcon,
  SociallyResponsibleIcon,
  SocialMediaAndEntertainmentIcon,
  StaffAgumentationIcon,
  StartupServicesIcon,
  TravelIcon,
  WebDevIcon,
  WorkCultureIcon,
  WorkForceIcon
} from 'assets/icons';

// ----------------------------------------------------------------------

export const numbersRegExp = /^\d{10}$/;
export const phoneRegExp = /^[0-9]{10}$/; /** /^[0-9\s]+$/ */

export const SkillSets = [
  'NodeJs',
  'ReactJs',
  'Javascripts',
  'Data Structure',
  'Python',
  'Java'
];

export const budgetItems = [
  'Under $1000',
  '$1000 - $5000',
  '$5000 - $10000',
  '$10000 - $20000',
  '$20000 and above'
];

export const plannedToStartItems = [
  'ASAP',
  'Few Weeks',
  'Few Months',
  'Not Sure'
];

export const technologyCategories = [
  'Front-End',
  'Back-End',
  'Mobile',
  'Machine Learning',
  'Database',
  'Devops',
  'CMS'
];

export const experienceItems = [
  '0 to 1 Year',
  '1 to 2 Years',
  '2 to 3 Years',
  '3 to 4 Years',
  '4 to 5 Years',
  '5+ Years'
];

export const footerLinks = [
  {
    name: 'Home',
    link: PAGES.root
  },
  {
    name: 'About',
    link: PAGES.aboutUs.absolutePath
  },
  {
    name: 'Blogs',
    link: PAGES.blogs.absolutePath
  },
  {
    name: 'Careers',
    link: PAGES.career.absolutePath
  },
  // {
  //   name: 'Remote Internship',
  //   link: PAGES.remoteInternship.absolutePath
  // },
  {
    name: 'Request A Quote',
    link: PAGES.requestAQuote.absolutePath
  },
  {
    name: 'Privacy Policy',
    link: PAGES.privacyPolicy.absolutePath
  },
  {
    name: 'Terms of Service',
    link: PAGES.termsOfService.absolutePath
  }
];

export const companyContactInfo = {
  email: {
    icon: <EmailIcon />,
    value: 'info@techechelons.net',
    label: 'info@techechelons.net'
  },
  location: {
    icon: <LocationOnIcon />,
    value: '8260 Greensboro Dr Suite 150, McLean, 22102',
    label: '8260 Greensboro Dr Suite 150, McLean, 22102'
  },
  career: {
    value: 'careers@techechelons.net',
    label: 'careers@techechelons.net'
  }
};

export const companySocials = [
  {
    name: 'Facebook',
    icon: <FacebookIcon />,
    link: 'https://www.facebook.com/TechechelonsInfosolutions'
  },
  {
    name: 'Twitter',
    icon: <TwitterIcon />,
    link: 'https://twitter.com/techechelons'
  },
  {
    name: 'LinkedIn',
    icon: <LinkedInIcon />,
    link: 'https://www.linkedin.com/company/techechelons-infosolutions-pvt-ltd/'
  }
];

export const toastMessages = {
  success: {
    auth: {
      forgotPassword: 'Reset password link sent successfully.',
      resetPassword:
        'Password changed successfully. Please sign in with new password.'
    },

    adminDashboard: {
      teamMemberSaved: 'Team Memeber saved successfully.',
      teamMemberUpdated: 'Team Memeber updated successfully.',
      teamMemberDeleted: 'Team Memeber deleted successfully.',
      categorySaved: 'Category saved successfully.',
      categoryUpdated: 'Category updated successfully.',
      categoryDeleted: 'Category deleted successfully.',
      technologySaved: 'Technology saved successfully.',
      technologyUpdated: 'Technology updated successfully.',
      technologyDeleted: 'Technology deleted successfully.',
      currentOpeningSaved: 'Current Opening saved successfully.',
      currentOpeningUpdated: 'Current Opening updated successfully.',
      currentOpeningDeleted: 'Current Opening deleted successfully.',
      availableInternshipSaved: 'Available Internship saved successfully.',
      availableInternshipUpdated: 'Available Internship updated successfully.',
      availableInternshipDeleted: 'Available Internship deleted successfully.',
      jobApplicantDeleted: 'Job Applicant deleted successfully.'
    },
    requestQuote:
      'Thank you for sending your query. We will get back to you soon.',
    currentOpening:
      'Thank you for sending your job application. We will get back to you soon.',
    internshipOpening:
      'Thank you for sending your internship application. We will get back to you soon.'
  },
  error: {
    auth: {
      notRegistered: 'Email is not registered yet.',
      invalidCredentials: 'Email or Password are incorrect.',
      invalidResetToken:
        'Link is either expired or invalid. Please try again with new link.'
    },
    adminDashboard: {
      teamMemberDuplicate: 'Team Memeber with the same name, already exist.',
      technologyDuplicate: 'Team Memeber with the same name, already exist.',
      categoryDuplicate: 'Team Memeber with the same name, already exist.',
      currentOpeningDuplicate:
        'Current Opening with the same position, already exist.',
      availableInternshipDuplicate:
        'Available Internship with the same position, already exist.'
    },
    common: 'Unable to process your request. Please try again.',
    currentOpeningDuplicate:
      'An application with this phone or email already exist.',
    internshipOpeningDuplicate:
      'An internship application with this phone or email already exist.'
  }
};

export const educationQualifications = [
  'Graduate',
  'Post Graduate',
  'Pursuing College',
  'Other'
];

export const internshipDuration = ['1-3 Months', '3-6 Months', '6+ Months'];

export const perksDetails = [
  {
    imgUrl: <SkillSetIcon />,
    title: 'Skill-set Growth',
    description:
      'After completing the internship, you will definitely observe 30%-50% of growth in your technical knowledge.'
  },
  {
    imgUrl: <MentorIcon />,
    title: 'Guidance by Industry Veterans',
    description:
      'Industry people who have expertise in particular area will provide guidance throughout the internship.'
  },
  {
    imgUrl: <OfficeIcon />,
    title: 'Office Environment',
    description:
      'Proper IT industry norms and culture, treatment like an employee, healthy learning environment.'
  },
  {
    imgUrl: <ConfidenceIcon />,
    title: 'Enhanced Self-Confidence',
    description:
      'Learning in good environment, interaction with company employees, team work will boost your self-confidence. On a scale of 1 to 10, you will surely rate yourself above 8 for your enhanced self-confidence.'
  },
  {
    imgUrl: <LiveProjectsIcon />,
    title: 'Proof of Concept / Live Projects',
    description:
      'Guidance in making your own POC’s/prototype which will have potential for real world applications. Working on company’s live projects, discovering real-time issues and providing effective solutions to it.'
  },
  {
    imgUrl: <HackathonIcon />,
    title: 'Hackathons / Mock Interviews',
    description:
      'Regular assessment of coding skills through online hackathons is provided to track your progress during the internship. Mock interviews would be arranged to prepare the candidate for market jobs.'
  }
];

export const remoteInternshipFAQs = [
  {
    question:
      'Doing an internship at Techechelons is free/paid/stipend based? ',
    answer:
      'Internships provided are not chargeable for interns. However, company may provide stipend if intern performs well.'
  },
  {
    question: 'What is the process of becoming an intern at Techechelons?',
    answer:
      'We keep interns based on interviews. We have limited seats and we prefer those candidates who are passionate, hard-working and eager to learn new technologies.'
  },
  {
    question: 'Why we are providing such internships? ',
    answer:
      'We believe in sharing quality knowledge with candidates who are willing to learn and passionate about their careers. Company sees the need of skilled resources in the market.'
  },
  {
    question: 'What is the benefit of company in providing internships? ',
    answer:
      'Company will be guiding interns in a way that interns are market ready. So, post completion of internship, company itself can hire on board or interns will be capable enough to reach companies who look for skilled resources.'
  },
  {
    question: 'Does this internship provide any certificate? ',
    answer:
      'Yes, company will provide completion certificate, but ONLY after intern completes his/her internship successfully.'
  },
  {
    question: 'Does this internship guarantee a job in your company? ',
    answer:
      'No, doing an internship does not guarantee a job here. Internship are solely meant to guide and make interns market ready. So, post internship company may or may not offer, based on performance and requirements'
  }
];

// Companies
export const trustedByCompanies = [
  {
    name: 'Dent Agency',
    logo: 'assets/images/companies/dent.png'
  },
  {
    name: 'Doss',
    logo: 'assets/images/companies/doss.png'
  },
  {
    name: 'Drop',
    logo: 'assets/images/companies/drop.png'
  },
  {
    name: 'NetReady',
    logo: 'assets/images/companies/netready.png'
  },
  {
    name: 'Outdoorgoats',
    logo: 'assets/images/companies/outdoorgoats.png'
  },
  {
    name: 'Bidano',
    logo: 'assets/images/companies/bidano.png'
  }
];

// Customer Testimonials
export const customerstories = [
  {
    imageUrl: 'assets/images/testimonials/outdoorgoats.png',
    name: 'Khyati Bhinde',
    designation: 'Co-Founder, OutdoorGoats',
    story:
      'Techechelons Infosolutions Pvt Ltd successfully completed the company’s app. They provided exemplary design solutions, and they had remarkable human resources. They were accessible, reliable, and flexible, which allowed them to maintain a strong and harmonious partnership.'
  },
  {
    imageUrl: '',
    name: 'Adam',
    designation: 'Co-Founder, Health For Life',
    story:
      'Great work , easy to communicate with , and very professional , great knowledge and understanding on building mobile app platforms. Lucky to have you guys doing our mobile projects !! thank you for great work on build our mobile application.'
  }
];

// Our Capabilities
export const ourCapabilities = [
  {
    title: 'Web Development',
    imageUrl: '/assets/images/home_oc_web_development.png',
    content: [
      {
        title: 'Full Stack Development.',
        description:
          'Whether you need a simple website or a complex web application, our full stack development service has you covered.'
      },
      {
        title: 'Content Management System.',
        description:
          'Our CMS solution is fully customizable, allowing you to tailor it to meet your specific needs and requirements.'
      },
      {
        title: 'Enterprise Application.',
        description:
          'If you are looking for a custom-built application, an integrated suite of applications, or a cloud-based software solution, Techechelons has you covered.'
      }
    ]
  },
  {
    title: 'AI & Machine Learning',
    imageUrl: '/assets/images/home_oc_ai_ml.png',
    content: [
      {
        title: 'Data Catalog.',
        description:
          'Our data catalog solution includes advanced search capabilities, data lineage tracking, and metadata management, allowing you to quickly find the data you need and trust its accuracy.'
      },
      {
        title: 'Machine Learning Model.',
        description:
          'Whether you need to build a predictive model for customer behavior, a classification model for sentiment analysis, or a recommendation system, we got you covered.'
      },
      {
        title: 'Automation.',
        description:
          'Our solution can automate complex and time-consuming tasks with ease, reducing errors, increasing productivity, and saving time.'
      }
    ]
  },
  {
    title: 'Mobile App Development',
    imageUrl: '/assets/images/home_oc_mobile_development.png',
    content: [
      {
        title: 'IOS Development.',
        description:
          'Our developers have extensive experience in iOS development and are committed to delivering solutions that are secure, scalable, and user-friendly.'
      },
      {
        title: 'Android Development.',
        description:
          'We use the latest technologies and tools, such as Java and Android Studio, to deliver high-performance and visually stunning Android apps that meet your business requirements.'
      },
      {
        title: 'Hybrid Development.',
        description:
          'With our Hybrid App Development as a Service, you can reach a wider audience and maximize your return on investment by building a single app that runs on multiple platforms.'
      }
    ]
  },
  {
    title: 'Blockchain & Web3',
    imageUrl: '/assets/images/home_oc_blockchain_web3.png',
    content: [
      {
        title: 'Smart Contracts.',
        description:
          'Our platform is customizable and flexible, allowing you to tailor it to meet your specific business needs and requirements.'
      },
      {
        title: 'NFT.',
        description:
          'We can help you can reach a global audience, monetize your digital creations, and secure the ownership and authenticity of your assets on the blockchain.'
      },
      {
        title: 'Decentralized Products.',
        description:
          'Techechelons can help you tap into the growing market for decentralized products and take advantage of the opportunities offered by blockchain technology.'
      }
    ]
  }
];

// Industry Experience
export const industryItems = [
  {
    icon: <TravelIcon />,
    name: 'Travel',
    link: PAGES.travel.relativePath
  },
  {
    icon: <HealthCareIcon />,
    name: 'Healthcare',
    link: PAGES.healthCare.relativePath
  },
  {
    icon: <EcommerceIcon />,
    name: 'E-Commerce',
    link: PAGES.eCommerce.relativePath
  },
  {
    icon: <EduTechIcon />,
    name: 'Edutech',
    link: PAGES.eduTech.relativePath
  },
  {
    icon: <SocialMediaAndEntertainmentIcon />,
    name: 'Social Media & Entertainment',
    link: PAGES.socialMediaAndEntertainment.relativePath
  },
  {
    icon: <RetailIcon />,
    name: 'Retail',
    link: PAGES.retail.relativePath
  },
  {
    icon: <FitnessAndSportIcon />,
    name: 'Fitness & Sports',
    link: PAGES.fitnessAndSports.relativePath
  },
  {
    icon: <MarketingAndAdvertisingIcon />,
    name: 'Marketing & Advertising',
    link: PAGES.marketingAndAdvertising.relativePath
  }
];

export const ourValues = [
  {
    imgUrl: <EndToEndIcon />,
    title: 'End to end solution provider',
    description:
      'We believe in offering end to end digital IT solutions for all the technology needs of our clients.'
  },
  {
    imgUrl: <EngagementModalIcon />,
    title: 'Customized engagement models',
    description:
      'With our custom software development company offerings, you can choose to customize the services you have availed by just offering us your requirements.'
  },
  {
    imgUrl: <WorkForceIcon />,
    title: 'Skilled & experienced workforce',
    description:
      'All our teammates are deft in their area of expertise and will deliver only the best results'
  },
  {
    imgUrl: <ProjectManagementIcon />,
    title: 'Best project management practices',
    description:
      'We follow the best project management practices to ensure we deliver on time and as per quality.'
  },
  {
    imgUrl: <CustomerSupportIcon />,
    title: 'Round the clock customer support',
    description:
      'Our representatives are always there to answer your queries as well as doubts, we never sleep!!!'
  },
  {
    imgUrl: <WorkCultureIcon />,
    title: 'Customer-centric attitude & work culture',
    description:
      'Our customers are the focal point to Techechelons & we work hard to keep it more human & fewer machines'
  },

  {
    imgUrl: <LeadershipIcon />,
    title: 'Highly effective & expert leadership',
    description:
      'Our management believes in guiding you to get the best possible results at all times.'
  },
  {
    imgUrl: <SociallyResponsibleIcon />,
    title: 'Socially responsible attitude',
    description:
      'We believe in serving the society as a whole by delivering what we promise without fail.'
  }
];

// Services Modal
export const services = [
  {
    icon: <WebDevIcon />,
    name: 'Web Development',
    link: PAGES.webdevelopment.relativePath
  },
  {
    icon: <AiMlIcon />,
    name: 'AI & Machine Learning',
    link: PAGES.aimldevelopment.relativePath
  },
  {
    icon: <StaffAgumentationIcon />,
    name: 'Staff Augmentation',
    link: PAGES.staffAugmentation.relativePath
  },
  {
    icon: <DevOpsIcon />,
    name: 'DevOps',
    link: PAGES.devopsdevelopment.relativePath
  },
  {
    icon: <BlockChainIcon />,
    name: 'Blockchain & Web3',
    link: PAGES.blockchaindevelopment.relativePath
  },
  {
    icon: <StartupServicesIcon />,
    name: 'Startup Services',
    link: PAGES.startupServices.relativePath
  },
  {
    icon: <AppDevelopmentIcon />,
    name: 'Mobile App Development',
    link: PAGES.mobiledevelopment.relativePath
  }
];

// Industry Modal
export const industry = [
  {
    icon: <TravelIcon color="#fff" />,
    name: 'Travel',
    link: PAGES.travel.relativePath
  },
  {
    icon: <HealthCareIcon color="#fff" />,
    name: 'Healthcare',
    link: PAGES.healthCare.relativePath
  },
  {
    icon: <EcommerceIcon color="#fff" />,
    name: 'E-Commerce',
    link: PAGES.eCommerce.relativePath
  },
  {
    icon: <EduTechIcon color="#fff" />,
    name: 'Edutech',
    link: PAGES.eduTech.relativePath
  },
  {
    icon: <SocialMediaAndEntertainmentIcon color="#fff" />,
    name: 'Social Media & Entertainment',
    link: PAGES.socialMediaAndEntertainment.relativePath
  },
  {
    icon: <RetailIcon color="#fff" />,
    name: 'Retail',
    link: PAGES.retail.relativePath
  },
  {
    icon: <FitnessAndSportIcon color="#fff" />,
    name: 'Fitness & Sports',
    link: PAGES.fitnessAndSports.relativePath
  },
  {
    icon: <MarketingAndAdvertisingIcon color="#fff" />,
    name: 'Marketing & Advertising',
    link: PAGES.marketingAndAdvertising.relativePath
  }
];
