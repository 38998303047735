/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Pagination component to override default Mui Pagination's style.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 14/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */
// ----------------------------------------------------------------------
/* Imports */
import { alpha } from '@mui/material';

// ----------------------------------------------------------------------

/**
 * Pagination contains the styles to override default Mui Pagination and it's children's styles.
 *
 * @component
 * @param theme - global theme object to provide colors, fonts, spacing, shadows etc.
 * @returns Add-on styles for MuiPagination
 */
export default function Pagination(theme) {
  /* Output */
  return {
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          ...theme.typography.subtitle2,
          borderRadius: '3px',
          color: theme.palette.common.white,
          minWidth: 28,
          minHeight: 28,
          width: 28,
          height: 28,
          marginLeft: theme.spacing(3),
          marginRight: theme.spacing(3),
          [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(1.5),
            marginRight: theme.spacing(1.5)
          },
          [theme.breakpoints.down(560)]: {
            marginLeft: theme.spacing(0.5),
            marginRight: theme.spacing(0.5)
          }
        },
        textPrimary: {
          '&.Mui-selected': {
            color: theme.palette.primary.main,
            '&:hover, &.Mui-focusVisible': {
              color: theme.palette.primary.main
            }
          }
        },
        outlined: {
          color: theme.palette.common.white,
          border: `1px solid ${theme.palette.grey[400]}`
        },
        outlinedPrimary: {
          border: `1px solid ${theme.palette.primary.main}`,
          '&:hover, &.Mui-focusVisible': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main
          },
          '&.Mui-selected': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
            '&:hover, &.Mui-focusVisible': {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main
            }
          }
        },
        previousNext: {
          // minHeight: 44,
          // padding: theme.spacing(1.5, 1.75),
          border: `1px solid ${theme.palette.primary.main}`,
          color: theme.palette.primary.main,
          '&.Mui-disabled': {
            opacity: 0.6
          }
        }
      }
    }
  };
}
