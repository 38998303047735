/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to define the configrations for project.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 16/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

export const appBaseUrl = process.env.REACT_APP_BASEURL;
export const apiBaseUrl = process.env.REACT_APP_API_BASEURL;
export const apiAccessToken = process.env.REACT_APP_API_ACCESSTOKEN;
export const imageBaseUrl = process.env.REACT_APP_API_BASEURL?.replace(
  '/api',
  ''
);
