/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Main app component to enter in project.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 14/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */
// ----------------------------------------------------------------------

/* Imports */
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

/* Relative Imports */
import ThemeConfig from 'theme';
import Routing from 'routes';
import ScrollToTop from 'components/ScrollToTop';
import NotistackProvider from 'components/NotistackProvider';
import { SessionProvider } from 'context/SessionContext';

// ----------------------------------------------------------------------

/**
 * App component to to set all the higher level components and routes.
 *
 * @component
 * @return {JSX.Element}
 */
function App() {
  /* Output */
  return (
    <HelmetProvider>
      <ThemeConfig>
        <SessionProvider>
          <NotistackProvider>
            <Router>
              <ScrollToTop />
              <Routing />
            </Router>
          </NotistackProvider>
        </SessionProvider>
      </ThemeConfig>
    </HelmetProvider>
  );
}

export default App;
