/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to create styles for user layout.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

export default {
  rootStyle: (theme) => ({
    backgroundColor: theme.palette.background.default,
    width: '100%',
    height: '100vh',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  }),
  containerStyle: (theme) => ({
    flex: 1,
    width: '100%',
    marginTop: '104px',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(10)
    }
  })
};
