/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to define the root routes.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

/* Relative Imports */
import AuthLayout from 'layout/AuthLayout';

/* Local Imports */
import { PAGES } from './paths';
import UserGuard from './guards/UserGuard';

// ----------------------------------------------------------------------

/* Pages Imports */
const SignInPage = lazy(() => import('views/auth/SignIn'));
const ForgotPasswordPage = lazy(() => import('views/auth/ForgotPassword'));
const ResetPasswordPage = lazy(() => import('views/auth/ResetPassword'));

// ----------------------------------------------------------------------

/**
 * assign components to routes
 *
 * @return {array}
 */
const AuthRoutes = [
  {
    path: PAGES.root,
    element: (
      <UserGuard>
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      </UserGuard>
    ),
    children: [
      {
        path: PAGES.signIn.relativePath,
        element: <SignInPage />
      },
      {
        path: PAGES.forgotPassword.relativePath,
        element: <ForgotPasswordPage />
      },
      {
        path: PAGES.resetPassword.relativePath,
        element: <ResetPasswordPage />
      }
    ]
  }
];

export default AuthRoutes;
