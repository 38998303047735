import PropTypes from 'prop-types';

import {
  AiMl,
  AppDevelopment,
  BlockChain,
  DevOps,
  StaffAgumentation,
  StartupServices,
  WebDev,
  Travel,
  HealthCare,
  Ecommerce,
  EduTech,
  SocialMediaAndEntertainment,
  Retail,
  FitnessAndSport,
  MarketingAndAdvertising,
  CustomerSupport,
  EndToEnd,
  EngagementModal,
  Leadership,
  ProjectManagement,
  PDF,
  PPT,
  XLSX,
  SociallyResponsible,
  WorkCulture,
  WorkForce,
  ASPDOTNET,
  NEXTJS,
  Responsive,
  Wordpress,
  Automation,
  Consultancy,
  Development,
  SmartContract,
  Tokenization,
  WebThree,
  DevOpsConsultancy,
  DisasterRecovery,
  Monitoring,
  Pipeline,
  Security,
  Android,
  Hybrid,
  IOS,
  Presentation,
  Prototyping,
  Wireframe,
  Contract,
  Payroll,
  TeamBuilding,
  Training,
  TrainingCost,
  Chatbot,
  ImageRecognition,
  NPL,
  PredictiveAnalysis,
  Reinforcement,
  ProductToMarketReach,
  ScalingGrowth,
  FundingChallenges,
  Angular,
  Node,
  React,
  DotNetNuke,
  DotNetCore,
  Confidence,
  LiveProjects,
  Mentor,
  Office,
  SkillSet,
  // Hackathon,
  Collab,
  Diverse,
  FamilyFeel,
  GrowthGraph,
  Impact,
  Fun,
  Hackathon,
  Smartwatch,
  Supplychain
} from './svgicons';

// Constants
const defaultColorIndustryExperience = '#FE5B00';
const defaultColorModalIcons = '#fff';
const defaultColorOurValues = '#000';
const defaultColorPerks = '#000';
const defaultColorOurBenefits = '#fff';
const defaultColorServicesGridIcon = '#ECEBED';

const propTypes = {
  color: PropTypes.string,
  isFilled: PropTypes.bool
};

function AiMlIcon({ color, ...rest }) {
  return <AiMl fill={color ?? defaultColorModalIcons} {...rest} />;
}
function AppDevelopmentIcon({ color, ...rest }) {
  return <AppDevelopment fill={color ?? defaultColorModalIcons} {...rest} />;
}
function BlockChainIcon({ color, ...rest }) {
  return <BlockChain fill={color ?? defaultColorModalIcons} {...rest} />;
}
function DevOpsIcon({ color, ...rest }) {
  return <DevOps fill={color ?? defaultColorModalIcons} {...rest} />;
}
function StaffAgumentationIcon({ color, ...rest }) {
  return <StaffAgumentation fill={color ?? defaultColorModalIcons} {...rest} />;
}
function StartupServicesIcon({ color, ...rest }) {
  return <StartupServices fill={color ?? defaultColorModalIcons} {...rest} />;
}
function WebDevIcon({ color, ...rest }) {
  return <WebDev fill={color ?? defaultColorModalIcons} {...rest} />;
}

// Industry Experience Icons
function TravelIcon({ color, ...rest }) {
  return <Travel fill={color ?? defaultColorIndustryExperience} {...rest} />;
}
function HealthCareIcon({ color, ...rest }) {
  return (
    <HealthCare fill={color ?? defaultColorIndustryExperience} {...rest} />
  );
}
function EcommerceIcon({ color, ...rest }) {
  return <Ecommerce fill={color ?? defaultColorIndustryExperience} {...rest} />;
}
function EduTechIcon({ color, ...rest }) {
  return <EduTech fill={color ?? defaultColorIndustryExperience} {...rest} />;
}
function SocialMediaAndEntertainmentIcon({ color, ...rest }) {
  return (
    <SocialMediaAndEntertainment
      fill={color ?? defaultColorIndustryExperience}
      {...rest}
    />
  );
}
function RetailIcon({ color, ...rest }) {
  return <Retail fill={color ?? defaultColorIndustryExperience} {...rest} />;
}
function FitnessAndSportIcon({ color, ...rest }) {
  return (
    <FitnessAndSport fill={color ?? defaultColorIndustryExperience} {...rest} />
  );
}
function MarketingAndAdvertisingIcon({ color, ...rest }) {
  return (
    <MarketingAndAdvertising
      fill={color ?? defaultColorIndustryExperience}
      {...rest}
    />
  );
}

// Our Values Icon
function CustomerSupportIcon({ color, ...rest }) {
  return <CustomerSupport fill={color ?? defaultColorOurValues} {...rest} />;
}
function EndToEndIcon({ color, ...rest }) {
  return <EndToEnd fill={color ?? defaultColorOurValues} {...rest} />;
}
function EngagementModalIcon({ color, ...rest }) {
  return <EngagementModal fill={color ?? defaultColorOurValues} {...rest} />;
}
function LeadershipIcon({ color, ...rest }) {
  return <Leadership fill={color ?? defaultColorOurValues} {...rest} />;
}
function ProjectManagementIcon({ color, ...rest }) {
  return <ProjectManagement fill={color ?? defaultColorOurValues} {...rest} />;
}
function SociallyResponsibleIcon({ color, ...rest }) {
  return (
    <SociallyResponsible fill={color ?? defaultColorOurValues} {...rest} />
  );
}
function WorkCultureIcon({ color, ...rest }) {
  return <WorkCulture fill={color ?? defaultColorOurValues} {...rest} />;
}
function WorkForceIcon({ color, ...rest }) {
  return <WorkForce fill={color ?? defaultColorOurValues} {...rest} />;
}
// Our Benefits
function CollabIcon({ color, ...rest }) {
  return <Collab fill={color ?? defaultColorOurBenefits} {...rest} />;
}
function DiverseIcon({ color, ...rest }) {
  return <Diverse fill={color ?? defaultColorOurBenefits} {...rest} />;
}
function FamilyFeelIcon({ color, ...rest }) {
  return <FamilyFeel fill={color ?? defaultColorOurBenefits} {...rest} />;
}
function GrowthGraphIcon({ color, ...rest }) {
  return <GrowthGraph fill={color ?? defaultColorOurBenefits} {...rest} />;
}
function ImpactIcon({ color, ...rest }) {
  return <Impact fill={color ?? defaultColorOurBenefits} {...rest} />;
}
function FunIcon({ color, ...rest }) {
  return <Fun fill={color ?? defaultColorOurBenefits} {...rest} />;
}

// Internship Perks
function ConfidenceIcon({ color, ...rest }) {
  return <Confidence fill={color ?? defaultColorPerks} {...rest} />;
}
function LiveProjectsIcon({ color, ...rest }) {
  return <LiveProjects fill={color ?? defaultColorPerks} {...rest} />;
}
function MentorIcon({ color, ...rest }) {
  return <Mentor fill={color ?? defaultColorPerks} {...rest} />;
}
function OfficeIcon({ color, ...rest }) {
  return <Office fill={color ?? defaultColorPerks} {...rest} />;
}
function SkillSetIcon({ color, ...rest }) {
  return <SkillSet fill={color ?? defaultColorPerks} {...rest} />;
}
function HackathonIcon({ color, ...rest }) {
  return <Hackathon fill={color ?? defaultColorPerks} {...rest} />;
}

// Services Grid Icons

// Web Development
function ASPDOTNETIcon({ color, ...rest }) {
  return <ASPDOTNET fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function NEXTJSIcon({ color, ...rest }) {
  return <NEXTJS fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function ResponsiveIcon({ color, ...rest }) {
  return <Responsive fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function WordpressIcon({ color, ...rest }) {
  return <Wordpress fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function AngularIcon({ color, ...rest }) {
  return <Angular fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function NodeIcon({ color, ...rest }) {
  return <Node fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function ReactIcon({ color, ...rest }) {
  return <React fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function DotNetNukeIcon({ color, ...rest }) {
  return <DotNetNuke fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function DotNetCoreIcon({ color, ...rest }) {
  return <DotNetCore fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
// Blockchain and WEB3
function AutomationIcon({ color, ...rest }) {
  return <Automation fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function ConsultancyIcon({ color, ...rest }) {
  return <Consultancy fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function DevelopmentIcon({ color, ...rest }) {
  return <Development fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function SmartContractIcon({ color, ...rest }) {
  return (
    <SmartContract fill={color ?? defaultColorServicesGridIcon} {...rest} />
  );
}
function TokenizationIcon({ color, ...rest }) {
  return (
    <Tokenization fill={color ?? defaultColorServicesGridIcon} {...rest} />
  );
}
function WebThreeIcon({ color, ...rest }) {
  return <WebThree fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
// DevOps Icons
function DevOpsConsultancyIcon({ color, ...rest }) {
  return (
    <DevOpsConsultancy fill={color ?? defaultColorServicesGridIcon} {...rest} />
  );
}
function DisasterRecoveryIcon({ color, ...rest }) {
  return (
    <DisasterRecovery fill={color ?? defaultColorServicesGridIcon} {...rest} />
  );
}
function MonitoringIcon({ color, ...rest }) {
  return <Monitoring fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function PipelineIcon({ color, ...rest }) {
  return <Pipeline fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function SecurityIcon({ color, ...rest }) {
  return <Security fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
// AI And ML
function ChatbotIcon({ color, ...rest }) {
  return <Chatbot fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function ImageRecognitionIcon({ color, ...rest }) {
  return (
    <ImageRecognition fill={color ?? defaultColorServicesGridIcon} {...rest} />
  );
}
function NPLIcon({ color, ...rest }) {
  return <NPL fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function PredictiveAnalysisIcon({ color, ...rest }) {
  return (
    <PredictiveAnalysis
      fill={color ?? defaultColorServicesGridIcon}
      {...rest}
    />
  );
}
function ReinforcementIcon({ color, ...rest }) {
  return (
    <Reinforcement fill={color ?? defaultColorServicesGridIcon} {...rest} />
  );
}
// Mobile App Development
function AndroidIcon({ color, ...rest }) {
  return <Android fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function HybridIcon({ color, ...rest }) {
  return <Hybrid fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function IOSIcon({ color, ...rest }) {
  return <IOS fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
// Staff Agumentation
function ContractIcon({ color, ...rest }) {
  return <Contract fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function PayrollIcon({ color, ...rest }) {
  return <Payroll fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function TrainingIcon({ color, ...rest }) {
  return <Training fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function TeamBuildingIcon({ color, ...rest }) {
  return (
    <TeamBuilding fill={color ?? defaultColorServicesGridIcon} {...rest} />
  );
}
function TrainingCostIcon({ color, ...rest }) {
  return (
    <TrainingCost fill={color ?? defaultColorServicesGridIcon} {...rest} />
  );
}
// Startup
function PresentationIcon({ color, ...rest }) {
  return (
    <Presentation fill={color ?? defaultColorServicesGridIcon} {...rest} />
  );
}
function PrototypingIcon({ color, ...rest }) {
  return <Prototyping fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}
function WireframeIcon({ color, ...rest }) {
  return <Wireframe fill={color ?? defaultColorServicesGridIcon} {...rest} />;
}

function ProductToMarketReachIcon({ color, ...rest }) {
  return (
    <ProductToMarketReach
      fill={color ?? defaultColorServicesGridIcon}
      {...rest}
    />
  );
}
function ScalingGrowthIcon({ color, ...rest }) {
  return (
    <ScalingGrowth fill={color ?? defaultColorServicesGridIcon} {...rest} />
  );
}
function FundingChallengesIcon({ color, ...rest }) {
  return (
    <FundingChallenges fill={color ?? defaultColorServicesGridIcon} {...rest} />
  );
}
// Document_Icons
function PDFIcon({ color, ...rest }) {
  return <PDF fill={color} {...rest} />;
}

function PPTIcon({ color, ...rest }) {
  return <PPT fill={color} {...rest} />;
}

function XLSXIcon({ color, ...rest }) {
  return <XLSX fill={color} {...rest} />;
}

// Industry
function SmartwatchIcon({ color, ...rest }) {
  return <Smartwatch fill={color} {...rest} />;
}

function SupplychainIcon({ color, ...rest }) {
  return <Supplychain fill={color} {...rest} />;
}

AiMlIcon.propTypes = propTypes;
AppDevelopmentIcon.propTypes = propTypes;
BlockChainIcon.propTypes = propTypes;
DevOpsIcon.propTypes = propTypes;
StaffAgumentationIcon.propTypes = propTypes;
StartupServicesIcon.propTypes = propTypes;
WebDevIcon.propTypes = propTypes;
// Industry Experience Icons
TravelIcon.propTypes = propTypes;
HealthCareIcon.propTypes = propTypes;
EcommerceIcon.propTypes = propTypes;
EduTechIcon.propTypes = propTypes;
SocialMediaAndEntertainmentIcon.propTypes = propTypes;
RetailIcon.propTypes = propTypes;
FitnessAndSportIcon.propTypes = propTypes;
MarketingAndAdvertisingIcon.propTypes = propTypes;
// Our Values Icon
CustomerSupportIcon.propTypes = propTypes;
EndToEndIcon.propTypes = propTypes;
EngagementModalIcon.propTypes = propTypes;
LeadershipIcon.propTypes = propTypes;
ProjectManagementIcon.propTypes = propTypes;
SociallyResponsibleIcon.propTypes = propTypes;
WorkCultureIcon.propTypes = propTypes;
WorkForceIcon.propTypes = propTypes;
// Our Benefits
CollabIcon.propTypes = propTypes;
DiverseIcon.propTypes = propTypes;
FamilyFeelIcon.propTypes = propTypes;
GrowthGraphIcon.propTypes = propTypes;
ImpactIcon.propTypes = propTypes;
FunIcon.propTypes = propTypes;

// Remote Internship Perks
ConfidenceIcon.propTypes = propTypes;
LiveProjectsIcon.propTypes = propTypes;
MentorIcon.propTypes = propTypes;
OfficeIcon.propTypes = propTypes;
SkillSetIcon.propTypes = propTypes;
HackathonIcon.propTypes = propTypes;

// Services Grid Icons
// Web Development
ASPDOTNETIcon.propTypes = propTypes;
NEXTJSIcon.propTypes = propTypes;
ResponsiveIcon.propTypes = propTypes;
WordpressIcon.propTypes = propTypes;
AngularIcon.propTypes = propTypes;
NodeIcon.propTypes = propTypes;
ReactIcon.propTypes = propTypes;
DotNetNukeIcon.propTypes = propTypes;
DotNetCoreIcon.propTypes = propTypes;
// BlockChain and WEB3
AutomationIcon.propTypes = propTypes;
ConsultancyIcon.propTypes = propTypes;
DevelopmentIcon.propTypes = propTypes;
SmartContractIcon.propTypes = propTypes;
TokenizationIcon.propTypes = propTypes;
WebThreeIcon.propTypes = propTypes;
// DevOps Icons
DevOpsConsultancyIcon.propTypes = propTypes;
DisasterRecoveryIcon.propTypes = propTypes;
MonitoringIcon.propTypes = propTypes;
PipelineIcon.propTypes = propTypes;
SecurityIcon.propTypes = propTypes;
// AI And ML
ChatbotIcon.propTypes = propTypes;
ImageRecognitionIcon.propTypes = propTypes;
NPLIcon.propTypes = propTypes;
PredictiveAnalysisIcon.propTypes = propTypes;
ReinforcementIcon.propTypes = propTypes;
// Mobile App Development
AndroidIcon.propTypes = propTypes;
HybridIcon.propTypes = propTypes;
IOSIcon.propTypes = propTypes;
// Staff Agumentation
ContractIcon.propTypes = propTypes;
PayrollIcon.propTypes = propTypes;
TrainingIcon.propTypes = propTypes;
TeamBuildingIcon.propTypes = propTypes;
TrainingCostIcon.propTypes = propTypes;
// Startup
PresentationIcon.propTypes = propTypes;
PrototypingIcon.propTypes = propTypes;
WireframeIcon.propTypes = propTypes;
ProductToMarketReachIcon.propTypes = propTypes;
ScalingGrowthIcon.propTypes = propTypes;
FundingChallengesIcon.propTypes = propTypes;

// Industry
SmartwatchIcon.propTypes = propTypes;
SupplychainIcon.propTypes = propTypes;
export {
  AiMlIcon,
  AppDevelopmentIcon,
  BlockChainIcon,
  DevOpsIcon,
  StaffAgumentationIcon,
  StartupServicesIcon,
  WebDevIcon,
  TravelIcon,
  HealthCareIcon,
  EcommerceIcon,
  EduTechIcon,
  SocialMediaAndEntertainmentIcon,
  RetailIcon,
  FitnessAndSportIcon,
  MarketingAndAdvertisingIcon,
  CustomerSupportIcon,
  EndToEndIcon,
  EngagementModalIcon,
  LeadershipIcon,
  ProjectManagementIcon,
  SociallyResponsibleIcon,
  WorkCultureIcon,
  WorkForceIcon,
  ASPDOTNETIcon,
  NEXTJSIcon,
  ResponsiveIcon,
  WordpressIcon,
  AngularIcon,
  NodeIcon,
  ReactIcon,
  DotNetNukeIcon,
  DotNetCoreIcon,
  AutomationIcon,
  ConsultancyIcon,
  DevelopmentIcon,
  SmartContractIcon,
  TokenizationIcon,
  WebThreeIcon,
  DevOpsConsultancyIcon,
  DisasterRecoveryIcon,
  MonitoringIcon,
  PipelineIcon,
  SecurityIcon,
  AndroidIcon,
  HybridIcon,
  IOSIcon,
  PresentationIcon,
  PrototypingIcon,
  WireframeIcon,
  ChatbotIcon,
  ImageRecognitionIcon,
  NPLIcon,
  PredictiveAnalysisIcon,
  ReinforcementIcon,
  ContractIcon,
  PayrollIcon,
  TrainingIcon,
  TeamBuildingIcon,
  TrainingCostIcon,
  ProductToMarketReachIcon,
  ScalingGrowthIcon,
  FundingChallengesIcon,
  ConfidenceIcon,
  LiveProjectsIcon,
  MentorIcon,
  OfficeIcon,
  SkillSetIcon,
  HackathonIcon,
  CollabIcon,
  DiverseIcon,
  FamilyFeelIcon,
  GrowthGraphIcon,
  ImpactIcon,
  FunIcon,
  PDFIcon,
  PPTIcon,
  XLSXIcon,
  SmartwatchIcon,
  SupplychainIcon
};
