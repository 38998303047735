/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to define the services related to user.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */

/* Relative Imports */
import axiosInstance from 'config/axiosConfig';

// ----------------------------------------------------------------------

export const loginUserRequest = (email, password, rememberMe) => {
  return axiosInstance
    .post('/auth/LoginUser', {
      Email: email,
      Password: password,
      RememberMe: rememberMe
    })
    .then((response) => response.data);
};

export const getUserProfileRequest = (token) => {
  return axiosInstance
    .get('/auth/GetUserProfile', {
      headers: {
        authorization: `Bearer ${token}`
      }
    })
    .then((response) => response.data);
};

export const forgotPasswordRequest = (email, resetPasswordUrl) => {
  return axiosInstance
    .post('/auth/ForgotPassword', {
      Email: email,
      ResetPasswordUrl: resetPasswordUrl
    })
    .then((response) => response.data);
};

export const resetPasswordRequest = (token, newPassword) => {
  return axiosInstance
    .post('/auth/ResetPassword', {
      Token: token,
      NewPassword: newPassword
    })
    .then((response) => response.data);
};
