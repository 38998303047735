/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to create styles for Hamburger menu component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Shashank P
 * Date Created: 06/Jan/23
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/**
 * Style object for Hamburger menu component
 *
 * @return {object}
 */
export default {
  hamburgerIcon: (theme) => ({
    color: theme.palette.common.white
  }),
  crossButtonBox: {
    display: 'flex',
    justifyContent: 'flex-start'
  },
  crossButton: (theme) => ({
    color: theme.palette.common.white,
    padding: 2
  }),
  menuStyle: (theme) => ({
    flex: 1,
    overflowY: 'auto',
    padding: 0,
    '& .MuiListItemButton-root': {
      padding: theme.spacing(1.25, 2.25),
      '&:hover': {
        color: theme.palette.primary.main,
        '& .MuiListItemText-root': {
          '&:hover': {
            color: theme.palette.info.main
          }
        }
      },
      '&.Mui-selected': {
        color: theme.palette.primary.main,
        '& .MuiListItemText-root': {
          color: theme.palette.info.main,
          '& .MuiTypography-root': {
            fontWeight: theme.typography.fontWeightSemiBold
          }
        }
      }
    }
  }),
  activeExpandIcon: (theme) => ({
    color: theme.palette.info.main
  }),
  expandIcon: (theme) => ({
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.info.main
    }
  }),
  subMenuStyle: (theme) => ({
    px: 0.5,
    py: 1.5,
    boxShadow: theme.customShadows.z24
  }),
  contactUs: (theme) => ({
    margin: theme.spacing(2.25)
  })
};
