/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to define the configurations for axios.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 16/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import axios from 'axios';

/* Local Imports */
import { apiBaseUrl, apiAccessToken } from './config';

// ----------------------------------------------------------------------

const axiosConfig = axios.create({
  baseURL: apiBaseUrl,
  headers: {
    Authorization: `Bearer ${apiAccessToken}`
  }
});

export default axiosConfig;
