/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to create scrollbar component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo } from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { Box } from '@mui/material';

/* Local Imports */
import styles from './index.style';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {object} sx
 */
const propTypes = {
  children: PropTypes.object,
  sx: PropTypes.object
};

/**
 * @typedef defaultProps
 * @property {object} sx
 */
const defaultProps = {
  sx: undefined
};

// ----------------------------------------------------------------------

/**
 * styling the scrollbar for Website.
 *
 * @component
 * @param {node} children - contains data or component
 * @param {object} sx - contains the styles
 * @return {JSX.Element}
 */
function Scrollbar({ children, sx, ...other }) {
  /* Constants */
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  /* Output */
  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <Box sx={styles.rootStyle}>
      <SimpleBarReact
        timeout={500}
        clickOnTrack={false}
        style={{ maxHeight: '100%', ...sx }}
        {...other}
      >
        {children}
      </SimpleBarReact>
    </Box>
  );
}

Scrollbar.propTypes = propTypes;
Scrollbar.defaultProps = defaultProps;
export default memo(Scrollbar);
