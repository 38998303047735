/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to define the root routes.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { lazy } from 'react';
import { Outlet } from 'react-router-dom';

/* Relative Imports */
import MainLayout from 'layout/MainLayout';

/* Local Imports */
import { PAGES } from './paths';

// ----------------------------------------------------------------------

/* Pages Imports */
const HomePage = lazy(() => import('views/home'));
const AboutUsPage = lazy(() => import('views/about'));
const RequestAQuote = lazy(() => import('views/request-a-quote'));
const CareersPage = lazy(() => import('views/careers'));
const RemoteInternshipPage = lazy(() => import('views/remote-internships'));
const BlogsPage = lazy(() => import('views/blogs'));
const BlogDetailPage = lazy(() => import('views/blogs/BlogDetail'));
const PrivacyPolicyPage = lazy(() => import('views/privacy-policy'));
const TermsOfServicePage = lazy(() => import('views/terms-of-service'));

// Services
const WebDevelopment = lazy(() => import('views/services/WebDevelopment'));
const MobileDevelopment = lazy(() =>
  import('views/services/MobileDevelopment')
);
const BlockchainDevelopment = lazy(() =>
  import('views/services/BlockchainDevelopment')
);
const AiMlDevelopment = lazy(() => import('views/services/AiMlDevelopment'));
const DevOpsDevelopment = lazy(() =>
  import('views/services/DevOpsDevelopment')
);
const StaffAugmentation = lazy(() =>
  import('views/services/StaffAugmentation')
);
const StartupServices = lazy(() => import('views/services/StartupServices'));
const ComingSoonPage = lazy(() => import('views/page-coming-soon'));
const NotFoundPage = lazy(() => import('views/page-not-found'));

// Industry
const Travel = lazy(() => import('views/industry/Travel'));
const HealthCare = lazy(() => import('views/industry/HealthCare'));
const ECommerce = lazy(() => import('views/industry/ECommerce'));
const EduTech = lazy(() => import('views/industry/EduTech'));
const SocialMediaEntertainment = lazy(() =>
  import('views/industry/SocialMediaEntertainment')
);
const Retail = lazy(() => import('views/industry/Retail'));
const FitnessSports = lazy(() => import('views/industry/FitnessSports'));
const MarketingAndAdvertising = lazy(() =>
  import('views/industry/MarketingAdvertising')
);

// ----------------------------------------------------------------------

/**
 * assign components to routes
 *
 * @return {array}
 */
const RootRoutes = [
  {
    path: PAGES.root,
    element: (
      <MainLayout>
        <Outlet />
      </MainLayout>
    ),
    children: [
      {
        index: true,
        element: <HomePage />
      },
      {
        path: PAGES.aboutUs.relativePath,
        element: <AboutUsPage />
      },
      {
        path: PAGES.blogs.relativePath,
        children: [
          { index: true, element: <BlogsPage /> },
          {
            path: PAGES.blogs.detail.relativePath,
            element: <BlogDetailPage />
          }
        ]
      },
      {
        path: PAGES.career.relativePath,
        element: <CareersPage />
      },
      {
        path: PAGES.remoteInternship.relativePath,
        element: <RemoteInternshipPage />
      },
      {
        path: PAGES.requestAQuote.relativePath,
        element: <RequestAQuote />
      },
      {
        path: PAGES.privacyPolicy.relativePath,
        element: <PrivacyPolicyPage />
      },
      {
        path: PAGES.termsOfService.relativePath,
        element: <TermsOfServicePage />
      },

      {
        path: PAGES.webdevelopment.relativePath,
        element: <WebDevelopment />
      },
      {
        path: PAGES.mobiledevelopment.relativePath,
        element: <MobileDevelopment />
      },
      {
        path: PAGES.blockchaindevelopment.relativePath,
        element: <BlockchainDevelopment />
      },
      {
        path: PAGES.aimldevelopment.relativePath,
        element: <AiMlDevelopment />
      },
      {
        path: PAGES.devopsdevelopment.relativePath,
        element: <DevOpsDevelopment />
      },
      {
        path: PAGES.staffAugmentation.relativePath,
        element: <StaffAugmentation />
      },
      {
        path: PAGES.startupServices.relativePath,
        element: <StartupServices />
      },
      {
        path: PAGES.travel.relativePath,
        element: <Travel />
      },
      {
        path: PAGES.healthCare.relativePath,
        element: <HealthCare />
      },
      {
        path: PAGES.eCommerce.relativePath,
        element: <ECommerce />
      },
      {
        path: PAGES.eduTech.relativePath,
        element: <EduTech />
      },
      {
        path: PAGES.socialMediaAndEntertainment.relativePath,
        element: <SocialMediaEntertainment />
      },
      {
        path: PAGES.retail.relativePath,
        element: <Retail />
      },
      {
        path: PAGES.fitnessAndSports.relativePath,
        element: <FitnessSports />
      },
      {
        path: PAGES.marketingAndAdvertising.relativePath,
        element: <MarketingAndAdvertising />
      },
      {
        path: '*',
        element: <NotFoundPage />
      }
    ]
  }
];

export default RootRoutes;
