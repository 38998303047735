/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to create user guard.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

/* Relative Imports */
import { PAGES, PAGE_ADMIN_DASHBOARD } from 'routes/paths';
import SessionContext from 'context/SessionContext';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {node} children
 */
const propTypes = {
  children: PropTypes.element.isRequired
};

// ----------------------------------------------------------------------

/**
 * Component to define protectation layout for pages, which are not accessible after login
 *
 * @component
 * @param {node} children - contains the child components
 * @returns {JSX.Element}
 */
function UserGuard({ children }) {
  /* Hooks */
  const context = useContext(SessionContext);
  const location = useLocation();
  const returnUrl = new URLSearchParams(location.search).get('returnurl');

  /* Output */
  if (context.isAuthenticated) {
    const redirectPath = PAGE_ADMIN_DASHBOARD.categories.absolutePath;
    return <Navigate to={returnUrl || redirectPath} state={location.state} />;
  }

  return children;
}

UserGuard.propTypes = propTypes;
export default UserGuard;
