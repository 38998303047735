/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to create auth guard.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

/* Relative Imports */
import { PAGES } from 'routes/paths';
import SessionContext from 'context/SessionContext';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {node} children
 */
const propTypes = {
  children: PropTypes.element.isRequired
};

// ----------------------------------------------------------------------

/**
 * Component to define protectation layout for pages, which are not accessible without login
 *
 * @component
 * @param {node} children - contains the child components
 * @returns {JSX.Element}
 */
function AuthGuard({ children }) {
  /* Hooks */
  const context = useContext(SessionContext);
  const location = useLocation();
  /* Output */
  if (!context.isAuthenticated) {
    return (
      <Navigate
        to={`${PAGES.signIn.absolutePath}?returnurl=${location.pathname}`}
      />
    );
  }

  return children;
}

AuthGuard.propTypes = propTypes;
export default AuthGuard;
