/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to define all the paths.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Constants */
const ROOT_ADMIN_DASHBOARD = 'admin-dashboard';

/* Root Pages */

export const PAGES = {
  root: '/',
  signIn: {
    relativePath: 'signin',
    absolutePath: '/signin'
  },
  forgotPassword: {
    relativePath: 'forgot-password',
    absolutePath: '/forgot-password'
  },
  resetPassword: {
    relativePath: 'reset-password/:token',
    absolutePath: '/reset-password/:token'
  },
  aboutUs: {
    relativePath: 'about',
    absolutePath: '/about'
  },
  services: {
    relativePath: 'services',
    absolutePath: '/services',
    detail: {
      relativePath: ':id',
      absolutePath: '/services/:id'
    }
  },
  industry: {
    relativePath: 'industry',
    absolutePath: '/industry',
    detail: {
      relativePath: ':id',
      absolutePath: '/industry/:id'
    }
  },
  caseStudies: {
    relativePath: 'case-studies',
    absolutePath: '/case-studies',
    detail: {
      relativePath: ':id',
      absolutePath: '/case-studies/:id'
    }
  },
  blogs: {
    relativePath: 'blogs',
    absolutePath: '/blogs',
    detail: {
      relativePath: ':id',
      absolutePath: '/blogs/:seoname'
    }
  },
  career: {
    relativePath: 'careers',
    absolutePath: '/careers'
  },
  remoteInternship: {
    relativePath: 'remote-internship',
    absolutePath: '/remote-internship'
  },
  requestAQuote: {
    relativePath: 'request-a-quote',
    absolutePath: `/request-a-quote`
  },
  privacyPolicy: {
    relativePath: 'privacy-policy',
    absolutePath: `/privacy-policy`
  },
  termsOfService: {
    relativePath: 'terms-of-service',
    absolutePath: `/terms-of-service`
  },
  wireframing: {
    relativePath: 'wireframing',
    absolutePath: `/wireframing`
  },
  webdevelopment: {
    relativePath: '/services/web-development',
    absolutePath: `/services/web-development`
  },
  mobiledevelopment: {
    relativePath: '/services/mobile-development',
    absolutePath: `/services/mobile-development`
  },
  blockchaindevelopment: {
    relativePath: '/services/blockchain-development',
    absolutePath: `/services/blockchain-development`
  },
  aimldevelopment: {
    relativePath: '/services/aiml-development',
    absolutePath: `/services/aiml-development`
  },
  devopsdevelopment: {
    relativePath: '/services/devops-development',
    absolutePath: `/services/devops-development`
  },
  staffAugmentation: {
    relativePath: '/services/staff-augmentation',
    absolutePath: `/services/staff-augmentation`
  },
  startupServices: {
    relativePath: '/services/startup-services',
    absolutePath: `/services/startup-services`
  },
  travel: {
    relativePath: '/industry/travel',
    absolutePath: `/industry/travel`
  },
  healthCare: {
    relativePath: '/industry/health-care',
    absolutePath: `/industry/health-care`
  },
  eCommerce: {
    relativePath: '/industry/e-commerce',
    absolutePath: `/industry/e-commerce`
  },
  eduTech: {
    relativePath: '/industry/edu-tech',
    absolutePath: `/industry/edu-tech`
  },
  socialMediaAndEntertainment: {
    relativePath: '/industry/social-media-entertainment',
    absolutePath: `/industry/social-media-entertainment`
  },
  retail: {
    relativePath: '/industry/retail',
    absolutePath: `/industry/retail`
  },
  fitnessAndSports: {
    relativePath: '/industry/fitness-and-sports',
    absolutePath: `/industry/fitness-and-sports`
  },
  marketingAndAdvertising: {
    relativePath: '/industry/marketing-and-advertising',
    absolutePath: `/industry/marketing-and-advertising`
  }
};

/* Admin Dashboard Pages */
export const PAGE_ADMIN_DASHBOARD = {
  root: {
    relativePath: ROOT_ADMIN_DASHBOARD,
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}`
  },
  categories: {
    relativePath: 'categories',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/categories`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/categories/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/categories/edit/:id`
    }
  },
  technologies: {
    relativePath: 'technologies',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/technologies`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/technologies/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/technologies/edit/:id`
    }
  },
  faqs: {
    relativePath: 'faqs',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/faqs`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/faqs/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/faqs/edit/:id`
    }
  },
  teamMembers: {
    relativePath: 'team-members',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/team-members`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/team-members/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/team-members/edit/:id`
    }
  },
  blogs: {
    relativePath: 'blogs',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/blogs`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/blogs/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/blogs/edit/:id`
    }
  },
  currentOpenings: {
    relativePath: 'current-openings',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/current-openings`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/current-openings/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/current-openings/edit/:id`
    }
  },
  availableInternship: {
    relativePath: 'available-internship',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/available-internship`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/available-internship/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/available-internship/edit/:id`
    }
  },
  requestAQuote: {
    relativePath: 'request-a-quote',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/request-a-quote`
  },
  careerEnquiry: {
    relativePath: 'career-enquiry',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/career-enquiry`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/career-enquiry/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/career-enquiry/edit/:id`
    }
  },
  internshipEnquiry: {
    relativePath: 'internship-enquiry',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/internship-enquiry`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/internship-enquiry/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/internship-enquiry/edit/:id`
    }
  },
  caseStudies: {
    relativePath: 'case-studies',
    absolutePath: `/${ROOT_ADMIN_DASHBOARD}/case-studies`,
    create: {
      relativePath: 'create',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/case-studies/create`
    },
    edit: {
      relativePath: 'edit/:id',
      absolutePath: `/${ROOT_ADMIN_DASHBOARD}/case-studies/edit/:id`
    }
  }
};
