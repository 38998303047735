/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to create Footer for all pages.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Shashank P
 * Date Created: 05/Jan/2023
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo } from 'react';
import {
  Box,
  Container,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography
} from '@mui/material';

/* Relative Imports */
import { PAGES } from 'routes/paths';
import WebsiteLogo from 'components/WebsiteLogo';
import {
  companyContactInfo,
  companySocials,
  footerLinks
} from 'constants/appConstant';

/* Local Imports */
import styles from './index.style';

// ----------------------------------------------------------------------

/**
 * footer component of main site
 * @component
 * @returns {JSX.Element}
 */
function Footer() {
  /* Constants */

  return (
    /* Output */
    <Box sx={styles.footer}>
      <Container>
        <Box sx={styles.footerContent}>
          <Box sx={styles.footerLeftContent}>
            <Box sx={styles.logoBox}>
              <WebsiteLogo />
            </Box>
            <Typography>
              Techechelons Infosolutions Private Limited is a fully-fledged IT
              products and services company with the development center,
              established in Pune and headquarters in Mumbai.
            </Typography>
          </Box>
          <Box sx={styles.footerRightContent}>
            <Box sx={styles.footerQuickLinks}>
              <Typography variant="h5" sx={styles.footerHeadings}>
                Quick Links
              </Typography>
              <List sx={[styles.linkList, styles.linkListColumnStyle]}>
                {footerLinks.map((item, index) => (
                  <ListItemButton
                    key={index}
                    href={item.link}
                    target={
                      item.link === PAGES.privacyPolicy.absolutePath ||
                      item.link === PAGES.termsOfService.absolutePath
                        ? '_blank'
                        : '_self'
                    }
                  >
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                ))}
              </List>
            </Box>
            <Box sx={styles.footerContactUs}>
              <Typography variant="h5" sx={styles.footerHeadings}>
                Contact Us
              </Typography>
              <List sx={styles.linkList}>
                <ListItem>
                  <ListItemIcon>{companyContactInfo.email.icon}</ListItemIcon>
                  <Link
                    href={`mailto:${companyContactInfo.email.value}`}
                    underline="none"
                  >
                    <ListItemText primary={companyContactInfo.email.label} />
                  </Link>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    {companyContactInfo.location.icon}
                  </ListItemIcon>
                  <ListItemText primary={companyContactInfo.location.label} />
                </ListItem>
                {/* Add the new address here */}
                <ListItem>
                  <ListItemIcon>
                    {companyContactInfo.location.icon}
                  </ListItemIcon>
                  <ListItemText primary="WeWork Futura, Magarpatta Rd, Kirtane Baugh, Hadapsar, Pune, Maharashtra 411028" />
                </ListItem>
              </List>
            </Box>
          </Box>
        </Box>
        <Divider sx={styles.footerDivider} />
        <Box sx={styles.footerBottom}>
          <Typography
            variant="caption"
            color="common.white"
            sx={{ textAlign: 'left' }}
          >
            © {new Date().getFullYear()} Techechelons Infosolutions Pvt Ltd. All
            right reserved.
          </Typography>
          <Stack direction="row" spacing={3.5} sx={{ marginLeft: 'auto' }}>
            {companySocials.map((item, index) => (
              <IconButton
                key={index}
                href={item.link}
                target="_blank"
                sx={styles.footerSocialButton}
              >
                {item.icon}
              </IconButton>
            ))}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
}

export default memo(Footer);
