/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to create layout for logged in user pages.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

/* Local Imports */
import { Footer, Header } from './components';
import styles from './index.style';

// ----------------------------------------------------------------------

/**
 * @typedef propTypes
 * @property {node} children
 */
const propTypes = {
  children: PropTypes.node
};

// ----------------------------------------------------------------------

/**
 * Outer design layout for logged in user pages
 *
 * @component
 * @param {node} children - contains the child components
 */
function UserLayout({ children }) {
  /* Output */
  return (
    <Box sx={styles.rootStyle}>
      <Header />
      <Box sx={styles.containerStyle}>{children}</Box>
      <Footer />
    </Box>
  );
}

UserLayout.propTypes = propTypes;
export default UserLayout;
