/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to define the root routes.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

/* Relative Imports */
import AdminDashboardLayout from 'layout/AdminDashboardLayout';

/* Local Imports */
import { PAGE_ADMIN_DASHBOARD } from './paths';
import AuthGuard from './guards/AuthGuard';

// ----------------------------------------------------------------------

/* Admin Dashboard Module Imports */
const CreateCategoryPage = lazy(() =>
  import('views/admin-dashboard/categories/CreateCategory')
);

const ManageCategoryPage = lazy(() =>
  import('views/admin-dashboard/categories/ManageCategory')
);

const CreateTechnologyPage = lazy(() =>
  import('views/admin-dashboard/technologies/CreateTechnology')
);

const ManageTechnologyPage = lazy(() =>
  import('views/admin-dashboard/technologies/ManageTechnology')
);

const CreateFAQsPage = lazy(() =>
  import('views/admin-dashboard/faqs/CreateFAQs')
);

const ManageFAQsPage = lazy(() =>
  import('views/admin-dashboard/faqs/ManageFAQs')
);

const CreateTeamMembers = lazy(() =>
  import('views/admin-dashboard/team-member/CreateTeamMember')
);

const ManageTeamMembers = lazy(() =>
  import('views/admin-dashboard/team-member/ManageTeamMember')
);

const CreateBlogsPage = lazy(() =>
  import('views/admin-dashboard/blogs/CreateBlogs')
);

const ManageBlogsPage = lazy(() =>
  import('views/admin-dashboard/blogs/ManageBlogs')
);

const CreateCurrentOpeningsPage = lazy(() =>
  import('views/admin-dashboard/current-openings/CreateCurrentOpenings')
);

const ManageCurrentOpeningsPage = lazy(() =>
  import('views/admin-dashboard/current-openings/ManageCurrentOpenings')
);
const CreateAvailableInternshipsPage = lazy(() =>
  import(
    'views/admin-dashboard/available-internships/CreateAvailableInternships'
  )
);

const ManageAvailableInternshipsPage = lazy(() =>
  import(
    'views/admin-dashboard/available-internships/ManageAvailableInternships'
  )
);

const ManageRequestAQuotePage = lazy(() =>
  import('views/admin-dashboard/request-a-quote/ManageRequestAQuote')
);

const ManageCareerEnquiryPage = lazy(() =>
  import('views/admin-dashboard/career-enquiry/ManageCareerEnquiry')
);

const ManageInternshipEnquiryPage = lazy(() =>
  import('views/admin-dashboard/internship-enquiry/ManageInternshipEnquiry')
);

const CreateCaseStudiesPage = lazy(() =>
  import('views/admin-dashboard/case-studies/CreateCaseStudies')
);

const ManageCaseStudiesPage = lazy(() =>
  import('views/admin-dashboard/case-studies/ManageCaseStudies')
);
// ----------------------------------------------------------------------

/**
 * assign components to routes
 *
 * @returns {array}
 */
const AdminDashboardRoutes = [
  {
    path: PAGE_ADMIN_DASHBOARD.root.relativePath,
    element: (
      <AuthGuard>
        <AdminDashboardLayout>
          <Outlet />
        </AdminDashboardLayout>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={PAGE_ADMIN_DASHBOARD.categories.absolutePath} />
      },
      {
        path: PAGE_ADMIN_DASHBOARD.categories.relativePath,
        children: [
          {
            index: true,
            element: <ManageCategoryPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.categories.create.relativePath,
            element: <CreateCategoryPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.categories.edit.relativePath,
            element: <CreateCategoryPage />
          }
        ]
      },
      {
        path: PAGE_ADMIN_DASHBOARD.technologies.relativePath,
        children: [
          {
            index: true,
            element: <ManageTechnologyPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.technologies.create.relativePath,
            element: <CreateTechnologyPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.technologies.edit.relativePath,
            element: <CreateTechnologyPage />
          }
        ]
      },
      {
        path: PAGE_ADMIN_DASHBOARD.faqs.relativePath,
        children: [
          {
            index: true,
            element: <ManageFAQsPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.faqs.create.relativePath,
            element: <CreateFAQsPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.faqs.edit.relativePath,
            element: <CreateFAQsPage />
          }
        ]
      },
      {
        path: PAGE_ADMIN_DASHBOARD.teamMembers.relativePath,
        children: [
          {
            index: true,
            element: <ManageTeamMembers />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.teamMembers.create.relativePath,
            element: <CreateTeamMembers />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.teamMembers.edit.relativePath,
            element: <CreateTeamMembers />
          }
        ]
      },
      {
        path: PAGE_ADMIN_DASHBOARD.blogs.relativePath,
        children: [
          {
            index: true,
            element: <ManageBlogsPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.blogs.create.relativePath,
            element: <CreateBlogsPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.blogs.edit.relativePath,
            element: <CreateBlogsPage />
          }
        ]
      },
      {
        path: PAGE_ADMIN_DASHBOARD.currentOpenings.relativePath,
        children: [
          {
            index: true,
            element: <ManageCurrentOpeningsPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.currentOpenings.create.relativePath,
            element: <CreateCurrentOpeningsPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.currentOpenings.edit.relativePath,
            element: <CreateCurrentOpeningsPage />
          }
        ]
      },
      {
        path: PAGE_ADMIN_DASHBOARD.availableInternship.relativePath,
        children: [
          {
            index: true,
            element: <ManageAvailableInternshipsPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.availableInternship.create.relativePath,
            element: <CreateAvailableInternshipsPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.availableInternship.edit.relativePath,
            element: <CreateAvailableInternshipsPage />
          }
        ]
      },
      {
        path: PAGE_ADMIN_DASHBOARD.availableInternship.relativePath,
        children: [
          {
            index: true,
            element: <ManageAvailableInternshipsPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.availableInternship.create.relativePath,
            element: <CreateAvailableInternshipsPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.availableInternship.edit.relativePath,
            element: <CreateAvailableInternshipsPage />
          }
        ]
      },
      {
        path: PAGE_ADMIN_DASHBOARD.availableInternship.relativePath,
        children: [
          {
            index: true,
            element: <ManageAvailableInternshipsPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.availableInternship.create.relativePath,
            element: <CreateAvailableInternshipsPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.availableInternship.edit.relativePath,
            element: <CreateAvailableInternshipsPage />
          }
        ]
      },
      {
        path: PAGE_ADMIN_DASHBOARD.requestAQuote.relativePath,
        children: [
          {
            index: true,
            element: <ManageRequestAQuotePage />
          }
        ]
      },

      {
        path: PAGE_ADMIN_DASHBOARD.careerEnquiry.relativePath,
        children: [
          {
            index: true,
            element: <ManageCareerEnquiryPage />
          }
        ]
      },

      {
        path: PAGE_ADMIN_DASHBOARD.internshipEnquiry.relativePath,
        children: [
          {
            index: true,
            element: <ManageInternshipEnquiryPage />
          }
        ]
      },
      {
        path: PAGE_ADMIN_DASHBOARD.caseStudies.relativePath,
        children: [
          {
            index: true,
            element: <ManageCaseStudiesPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.caseStudies.create.relativePath,
            element: <CreateCaseStudiesPage />
          },
          {
            path: PAGE_ADMIN_DASHBOARD.caseStudies.edit.relativePath,
            element: <CreateCaseStudiesPage />
          }
        ]
      }
    ]
  }
];

export default AdminDashboardRoutes;
