/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Container component to override default Mui Container's style.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 14/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/**
 * Container contains the styles to override default Mui Container and it's children's styles.
 *
 * @component
 * @param theme - global theme object to provide colors, fonts, spacing, shadows etc.
 * @returns Add-on styles for MuiContainer
 */

export default function Container(theme) {
  /* Output */
  return {
    MuiContainer: {
      defaultProps: {
        maxWidth: 'xl'
      },
      styleOverrides: {
        root: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          [theme.breakpoints.up('laptop')]: {
            paddingLeft: `${theme.spacing(10)} !important`,
            paddingRight: `${theme.spacing(10)} !important`
          },
          [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(5),
            paddingRight: theme.spacing(5)
          },
          [theme.breakpoints.down(360)]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2)
          }
        }
      }
    }
  };
}
