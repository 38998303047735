export default {
  header: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    width: '100%',
    height: 80,
    position: 'fixed',
    zIndex: 999,
    [theme.breakpoints.up('md')]: {
      height: 104
    }
  }),
  headerContainer: (theme) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 2.25,
    width: '100%',
    height: '100%',
    paddingY: 2,
    [theme.breakpoints.up('md')]: {
      paddingY: 3
    }
  }),
  menuStyle: (theme) => ({
    padding: 0,
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& .MuiListItemButton-root': {
      padding: 0,
      margin: theme.spacing(1.25, 2.25),
      flexGrow: 0,
      minHeight: 0,
      '&:hover': {
        color: theme.palette.common.white,
        '& .MuiListItemText-root': {
          '&:hover': {
            color: theme.palette.primary.main
          }
        }
      },
      '&.Mui-selected': {
        color: theme.palette.common.white,
        '& .MuiListItemText-root': {
          position: 'relative',
          color: theme.palette.primary.main,
          '& .MuiTypography-root': {
            fontWeight: theme.typography.fontWeightSemiBold
          },
          '&::after': {
            content: '""',
            background:
              'url(/assets/images/lines/blue_48.svg) no-repeat center',
            backgroundSize: '100%',
            position: 'absolute',
            width: '100%',
            height: 12
          }
        }
      }
    }
  }),
  activeExpandIcon: (theme) => ({
    color: theme.palette.primary.main
  }),
  expandIcon: (theme) => ({
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.primary.main,
      span: {
        color: theme.palette.primary.main
      },
      svg: {
        color: theme.palette.primary.main
      }
    }
  }),
  menuPopoverStyle: {
    width: 'auto',
    maxWidth: 948
  },
  footer: (theme) => ({
    background: theme.palette.gradients.footer,
    pt: 10,
    marginTop: 'auto',
    color: 'text.secondary',
    [theme.breakpoints.down('md')]: {
      pt: 5
    }
  }),
  footerContent: (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 10,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
      gap: 5
    }
  }),
  footerLeftContent: (theme) => ({
    flex: 1,
    maxWidth: 360,
    [theme.breakpoints.down('md')]: {
      maxWidth: 800
    }
  }),
  footerRightContent: (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    gap: 30,
    [theme.breakpoints.down(1820)]: {
      gap: 20
    },
    [theme.breakpoints.down(1680)]: {
      gap: 10
    },
    [theme.breakpoints.down(1380)]: {
      gap: 7
    },
    [theme.breakpoints.down(1120)]: {
      gap: 5
    },
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.down(635)]: {
      flexDirection: 'column',
      justifyContent: 'flex-start'
    }
  }),
  footerContactUs: (theme) => ({
    maxWidth: 325,
    [theme.breakpoints.between('md', 1380)]: {
      maxWidth: 245
    },
    [theme.breakpoints.between(635, 715)]: {
      maxWidth: 245
    },
    [theme.breakpoints.down(635)]: {
      maxWidth: '100%'
    }
  }),
  logoBox: {
    mb: 2,
    height: 40
  },
  footerHeadings: (theme) => ({
    color: theme.palette.common.white,
    marginBottom: theme.spacing(3.5)
  }),
  linkList: (theme) => ({
    paddingY: 0,
    '& .MuiListItemButton-root': {
      color: theme.palette.text.secondary,
      minHeight: 0,
      p: 0,
      '&:not(:last-child)': {
        mb: 2
      }
    },
    '& .MuiListItem-root': {
      minHeight: 0,
      p: 0,
      '&:not(:last-child)': {
        mb: 3
      },
      '& .MuiListItemIcon-root': {
        color: theme.palette.common.white,
        alignSelf: 'flex-start',
        mt: 0.25
      },
      '& .MuiListItemText-root': {
        color: theme.palette.text.secondary
      }
    }
  }),
  linkListColumnStyle: (theme) => ({
    columnCount: 2,
    [theme.breakpoints.down(335)]: {
      columnCount: 'initial'
    }
  }),
  footerDivider: (theme) => ({
    borderColor: theme.palette.footerDivider,
    mt: 2
  }),
  footerBottom: {
    marginY: 2,
    display: 'flex',
    alignItems: 'center'
  },
  footerSocialButton: (theme) => ({
    color: theme.palette.common.white,
    '& svg': {
      fontSize: 24
    }
  }),
  copyRights: {
    flex: 1,
    textAlign: 'center'
  }
};
