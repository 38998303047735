/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to create styles for admin dashboard layout.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

export default {
  rootStyle: (theme) => ({
    backgroundColor: theme.palette.background.default,
    width: '100%',
    height: '100vh',
    display: 'flex',
    position: 'relative',
    overflow: 'hidden'
  }),
  leftPanel: (theme) => ({
    width: 264,
    height: '100%',
    borderRight: `1px solid ${theme.palette.divider}`
  }),
  sidebarDrawer: (theme) => ({
    backgroundColor: theme.palette.background.default
  }),
  logoContainer: (theme) => ({
    padding: theme.spacing(2)
  }),
  userProfile: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(1, 1, 3),
    padding: 2,
    borderRadius: `${theme.shape.borderRadiusXs}px`,
    [theme.breakpoints.down('md')]: {
      marginX: 2
    }
  }),
  profilePicture: {
    cursor: 'pointer',
    width: 48,
    height: 48,
    mr: 2
  },
  listContainer: {
    flex: 1,
    overflowY: 'auto',
    minHeight: 60
  },
  sidebarList: (theme) => ({
    padding: 0,
    '& .MuiListItemButton-root': {
      [theme.breakpoints.up('md')]: {
        paddingX: 2
      }
    }
  }),
  wrapperStyle: {
    flex: 1,
    height: '100%',
    position: 'relative',
    overflow: 'hidden'
  },
  containerStyle: {
    height: 'calc(100% - 64px)',
    mt: 8,
    overflow: 'auto'
  },
  contentStyle: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  header: (theme) => ({
    width: 'calc(100% - 264px)',
    height: 64,
    position: 'fixed',
    zIndex: 999,
    left: 264,
    top: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.common.white,
    paddingY: 1,
    boxShadow: theme.shadows[12],
    [theme.breakpoints.down('laptop')]: {
      width: '100%',
      left: 0
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: `${theme.spacing(3)} !important`,
      paddingRight: `${theme.spacing(3)} !important`
    }
  }),
  menuIcon: {
    mr: 3
  },
  rightOptions: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  accountPopover: {
    width: 280
  },
  popoverProfile: {
    my: 1.5,
    px: 2.5,
    flexWrap: 'nowrap'
  },
  popoverMenuItem: {
    typography: 'body2',
    py: 1,
    px: 2.5
  },
  logout: (theme) => ({
    width: '100%',
    justifyContent: 'center',
    color: theme.palette.text.primary
  }),
  footer: (theme) => ({
    textAlign: 'center',
    padding: theme.spacing(1.5),
    borderTop: `1px solid ${theme.palette.divider}`
  }),
  userName: (theme) => ({
    color: theme.palette.text.primary
  })
};
