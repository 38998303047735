/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to define tabs for sidebar.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import ArticleIcon from '@mui/icons-material/Article';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import PublicIcon from '@mui/icons-material/Public';
import CategoryIcon from '@mui/icons-material/Category';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import WorkIcon from '@mui/icons-material/Work';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';

/* Relative Imports */
import { PAGE_ADMIN_DASHBOARD } from 'routes/paths';

// ----------------------------------------------------------------------

/* Side bar tabs */
const SidebarConfig = [
  {
    title: 'Manage Blog Categories',
    href: PAGE_ADMIN_DASHBOARD.categories.absolutePath,
    icon: CategoryIcon
  },
  {
    title: 'Manage Blogs',
    href: PAGE_ADMIN_DASHBOARD.blogs.absolutePath,
    icon: ArticleIcon
  },
  {
    title: 'Manage Technologies',
    href: PAGE_ADMIN_DASHBOARD.technologies.absolutePath,
    icon: ImportantDevicesIcon
  },
  {
    title: 'Manage FAQs',
    href: PAGE_ADMIN_DASHBOARD.faqs.absolutePath,
    icon: QuestionAnswerIcon
  },
  {
    title: 'Manage Team Members',
    href: PAGE_ADMIN_DASHBOARD.teamMembers.absolutePath,
    icon: PublicIcon
  },

  {
    title: 'Case Studies',
    href: PAGE_ADMIN_DASHBOARD.caseStudies.absolutePath,
    icon: NewspaperIcon
  },
  {
    title: 'Manage Current Openings',
    href: PAGE_ADMIN_DASHBOARD.currentOpenings.absolutePath,
    icon: WorkIcon
  },
  {
    title: 'Manage Available Internships',
    href: PAGE_ADMIN_DASHBOARD.availableInternship.absolutePath,
    icon: WorkIcon
  },
  {
    title: 'Request a Quote',
    href: PAGE_ADMIN_DASHBOARD.requestAQuote.absolutePath,
    icon: RequestQuoteIcon
  },
  {
    title: 'Career Enquiry',
    href: PAGE_ADMIN_DASHBOARD.careerEnquiry.absolutePath,
    icon: HelpCenterIcon
  },
  {
    title: 'Internship Enquiry',
    href: PAGE_ADMIN_DASHBOARD.internshipEnquiry.absolutePath,
    icon: HelpCenterIcon
  }
];

export default SidebarConfig;
