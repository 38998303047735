/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to create Header for admin dashboard pages.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 21/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import React, { memo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  useTheme
} from '@mui/material';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';

/* Relative Imports */
import { PAGES } from 'routes/paths';
import WebsiteLogo from 'components/WebsiteLogo';
import MenuPopover from 'components/MenuPopover';
import Hamburger from 'components/Hamburger';
import ServicesModal from 'components/ServicesModal';
import { industry, services } from 'constants/appConstant';

/* Local Imports */
import styles from './index.style';

// ----------------------------------------------------------------------

/**
 * allow navigation between groups of content
 * @component
 * @returns {JSX.ELement}
 */
function Header() {
  /* Hooks */
  const { pathname } = useLocation();
  const theme = useTheme();
  const mdDownMatches = useMediaQuery(theme.breakpoints.down('md'));

  /* States */
  const [servicesAnchorEl, setServicesAnchorEl] = useState(null);
  const [industryAnchorEl, setIndustryAnchorEl] = useState(null);

  /* Constants */
  const servicesOpen = Boolean(servicesAnchorEl);
  const industryOpen = Boolean(industryAnchorEl);

  /* Functions */
  /**
   * function to open service popover
   * @param {object} event - service tab event
   * @return {void}
   */
  const handleServiesMenuOpen = (event) => {
    event.stopPropagation();
    setServicesAnchorEl(event.currentTarget);
  };

  /**
   * function to close popover
   * @return {void}
   */
  const handleServicesMenuClose = () => {
    setServicesAnchorEl(null);
  };

  /**
   * function to open service popover
   * @param {object} event - service tab event
   * @return {void}
   */
  const handleIndustryMenuOpen = (event) => {
    event.stopPropagation();
    setIndustryAnchorEl(event.currentTarget);
  };

  /**
   * function to close popover
   * @return {void}
   */
  const handleIndustryMenuClose = () => {
    setIndustryAnchorEl(null);
  };

  /* Output */
  return (
    <Box sx={styles.header}>
      <Container sx={styles.headerContainer}>
        <WebsiteLogo />
        {!mdDownMatches ? (
          <>
            <List sx={styles.menuStyle}>
              <ListItemButton
                href={PAGES.root}
                selected={pathname === PAGES.root}
              >
                <ListItemText primary="Home" />
              </ListItemButton>

              <ListItemButton
                href={PAGES.aboutUs.absolutePath}
                selected={pathname.includes(PAGES.aboutUs.absolutePath)}
              >
                <ListItemText primary="About" />
              </ListItemButton>

              <ListItemButton
                onClick={handleServiesMenuOpen}
                sx={[
                  styles.expandIcon,
                  servicesOpen ? styles.activeExpandIcon : {}
                ]}
                selected={pathname.includes(PAGES.services.absolutePath)}
              >
                <ListItemText primary="Services" />
                <IconButton
                  onClick={handleServiesMenuOpen}
                  sx={[
                    styles.expandIcon,
                    servicesOpen ? styles.activeExpandIcon : {}
                  ]}
                >
                  <ExpandMoreRoundedIcon />
                </IconButton>
              </ListItemButton>
              <ListItemButton
                onClick={handleIndustryMenuOpen}
                sx={[
                  styles.expandIcon,
                  industryOpen ? styles.activeExpandIcon : {}
                ]}
                selected={pathname.includes(PAGES.industry.absolutePath)}
              >
                <ListItemText primary="Industry" />
                <IconButton
                  onClick={handleIndustryMenuOpen}
                  sx={[
                    styles.expandIcon,
                    industryOpen ? styles.activeExpandIcon : {}
                  ]}
                >
                  <ExpandMoreRoundedIcon />
                </IconButton>
              </ListItemButton>

              <ListItemButton
                href={PAGES.blogs.absolutePath}
                selected={pathname.includes(PAGES.blogs.absolutePath)}
              >
                <ListItemText primary="Blogs" />
              </ListItemButton>

              <ListItemButton
                href={PAGES.career.absolutePath}
                selected={pathname.includes(PAGES.career.absolutePath)}
              >
                <ListItemText primary="Careers" />
              </ListItemButton>
            </List>
            <Button
              variant="contained"
              color="info"
              size="large"
              href={PAGES.requestAQuote.absolutePath}
            >
              Request A Quote
            </Button>
            <MenuPopover
              id="serviceMenu"
              open={servicesOpen}
              anchorEl={servicesAnchorEl}
              onClose={handleServicesMenuClose}
              contentStyle={styles.menuPopoverStyle}
            >
              <ServicesModal data={services} />
            </MenuPopover>
            <MenuPopover
              id="serviceMenu"
              open={industryOpen}
              anchorEl={industryAnchorEl}
              onClose={handleIndustryMenuClose}
              contentStyle={styles.menuPopoverStyle}
            >
              <ServicesModal data={industry} />
            </MenuPopover>
          </>
        ) : (
          <>
            <Hamburger />
          </>
        )}
      </Container>
    </Box>
  );
}

export default memo(Header);
