/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page to create Account popover component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Naishad Vaishnav
 * Date Created: 17/Nov/2022
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------

/* Imports */
import { memo, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  IconButton,
  MenuItem,
  Typography
} from '@mui/material';

/* Relative Imports */
import { PAGES } from 'routes/paths';
import SessionContext from 'context/SessionContext';
import MenuPopover from 'components/MenuPopover';
import MyAvatar from 'components/MyAvatar';
import { ConfirmDialog } from 'components/Dialog';
// import useSettings from 'hooks/useSettings';

/* Local Imports */
import styles from '../index.style';

// ----------------------------------------------------------------------

/**
 * Account/My Profile Popover for the logged in pages
 *
 * @component
 * @return {JSX.Element}
 */
function AccountPopover() {
  /* Hooks */
  const { user, LogoutUser } = useContext(SessionContext);
  // const { themeMode, switchMode } = useSettings();
  const navigate = useNavigate();
  /* States */
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  /* Constants */
  const open = Boolean(anchorEl);

  /**
   * function to open profile menu
   * @return {void}
   */
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  /**
   * function to close profile menu
   * @return {void}
   */
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  /**
   * function to open logout confirmation dialog
   * @return {void}
   */
  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  /**
   * function to close logout confirmation dialog
   * @return {void}
   */
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  /**
   * function to call on cancel logout
   * @return {void}
   */
  const handleLogoutCancel = () => {
    handleDialogClose();
    handleCloseMenu();
  };

  /**
   * function to logout on confirmation
   * @return {void}
   */
  const handleLogout = () => {
    LogoutUser();
    navigate(PAGES.root);
  };

  return (
    <>
      {user && (
        <>
          <IconButton onClick={handleOpenMenu}>
            <MyAvatar />
          </IconButton>
          <MenuPopover
            id="logout"
            open={open}
            anchorEl={anchorEl}
            onClose={handleCloseMenu}
            contentStyle={styles.accountPopover}
          >
            <Box sx={styles.popoverProfile}>
              <Typography variant="subtitle1" sx={styles.userName}>
                {user.FullName}
              </Typography>
              <Typography variant="body2" noWrap sx={styles.userName}>
                {user.Email}
              </Typography>
            </Box>
            <Divider />
            <MenuItem
              component={Button}
              onClick={handleDialogOpen}
              sx={[styles.popoverMenuItem, styles.logout]}
            >
              Logout
            </MenuItem>
          </MenuPopover>
          <ConfirmDialog
            open={openDialog}
            description="Are you sure you want to Logout?"
            agreeText="Logout"
            disagreeText="Cancel"
            onAgreeAction={handleLogout}
            onDisAgreeAction={handleLogoutCancel}
          />
        </>
      )}
    </>
  );
}

export default memo(AccountPopover);
