/**
 * @copyright @2022 Techechelons Infosolutions Pvt. Ltd. All rights reserved.
 * @description Page for Hamburger Menu Component.
 * --------------------------------------------------------------------
 * Creation Details
 * @author Shashank P
 * Date Created  : 06/Jan/2023
 * FDO Ref:
 * TDO Ref:
 * RTM Ref:
 * Test Case Ref:
 */

// ----------------------------------------------------------------------
/* Imports */
import React, { memo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SwipeableDrawer
} from '@mui/material';
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
  ExpandLessRounded as ExpandLessRoundedIcon
} from '@mui/icons-material';

/* Relative Imports */
import { PAGES } from 'routes/paths';
import { industry, services } from 'constants/appConstant';
import ServicesModalItem from 'components/ServicesModal/ServicesModalItem';

/* local Imports */
import styles from './index.style';

// ----------------------------------------------------------------------

/**
 *
 * Handles rendering of Hamburger Menu component
 *
 * @component
 * @return {JSX.Element}
 */

function HamburgerMenu() {
  /* Hooks */
  const { pathname } = useLocation();
  /* States */
  const [openHamburger, setOpenHamburger] = useState(false);
  const [industryExpanded, setIndustryExpanded] = useState(false);
  const [servicesExpand, setServicesExpanded] = useState(false);

  /* Output */
  return (
    <>
      <IconButton
        onClick={() => setOpenHamburger(true)}
        sx={styles.hamburgerIcon}
      >
        <MenuIcon />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        open={openHamburger}
        onOpen={() => setOpenHamburger(true)}
        onClose={() => setOpenHamburger(false)}
        sx={styles.Drawer}
      >
        <>
          <Box sx={styles.crossButtonBox}>
            <IconButton
              onClick={() => setOpenHamburger(false)}
              sx={styles.crossButton}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <List sx={styles.menuStyle}>
            <ListItemButton
              href={PAGES.root}
              selected={pathname === PAGES.root}
            >
              <ListItemText primary="Home" />
            </ListItemButton>
            <ListItemButton
              href={PAGES.aboutUs.absolutePath}
              selected={pathname.includes(PAGES.aboutUs.absolutePath)}
            >
              <ListItemText primary="About" />
            </ListItemButton>
            <ListItemButton
              onClick={(e) => {
                e.stopPropagation();
                setServicesExpanded(!servicesExpand);
              }}
              selected={pathname.includes(PAGES.services.absolutePath)}
            >
              <ListItemText primary="Services" />
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setServicesExpanded(!servicesExpand);
                }}
                sx={[
                  styles.expandIcon,
                  servicesExpand ? styles.activeExpandIcon : {}
                ]}
              >
                {servicesExpand ? (
                  <ExpandLessRoundedIcon />
                ) : (
                  <ExpandMoreRoundedIcon />
                )}
              </IconButton>
            </ListItemButton>
            {servicesExpand && (
              <Box sx={styles.subMenuStyle}>
                {services.map((item, index) => (
                  <ListItem key={index}>
                    <ServicesModalItem
                      isSelected={pathname.includes(item.link)}
                      serviceIcon={item.icon}
                      serviceName={item.name}
                      serviceLink={item.link}
                    />
                  </ListItem>
                ))}
              </Box>
            )}
            <ListItemButton
              onClick={(e) => {
                e.stopPropagation();
                setIndustryExpanded(!industryExpanded);
              }}
              selected={pathname.includes(PAGES.industry.absolutePath)}
            >
              <ListItemText primary="Industry" />
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setIndustryExpanded(!industryExpanded);
                }}
                sx={[
                  styles.expandIcon,
                  industryExpanded ? styles.activeExpandIcon : {}
                ]}
              >
                {industryExpanded ? (
                  <ExpandLessRoundedIcon />
                ) : (
                  <ExpandMoreRoundedIcon />
                )}
              </IconButton>
            </ListItemButton>
            {industryExpanded && (
              <Box sx={styles.subMenuStyle}>
                {industry.map((item, index) => (
                  <ListItem key={index}>
                    <ServicesModalItem
                      isSelected={pathname.includes(item.link)}
                      serviceIcon={item.icon}
                      serviceName={item.name}
                      serviceLink={item.link}
                    />
                  </ListItem>
                ))}
              </Box>
            )}
            <ListItemButton
              href={PAGES.blogs.absolutePath}
              selected={pathname.includes(PAGES.blogs.absolutePath)}
            >
              <ListItemText primary="Blogs" />
            </ListItemButton>
            <ListItemButton
              href={PAGES.career.absolutePath}
              selected={pathname.includes(PAGES.career.absolutePath)}
            >
              <ListItemText primary="Careers" />
            </ListItemButton>
          </List>
          <Button
            variant="contained"
            color="info"
            size="large"
            href={PAGES.requestAQuote.absolutePath}
            sx={styles.contactUs}
          >
            Request A Quote
          </Button>
        </>
      </SwipeableDrawer>
    </>
  );
}
export default memo(HamburgerMenu);
